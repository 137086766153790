<template>
  <div>
    <div class="topWrap">
      <div class="content">
        <h3>登录</h3>
        <el-form
          :model="form"
          :rules="rules"
          ref="ruleForm"
          :show-message="false"
        >
          <el-form-item prop="mobileOrEmail">
            <el-input v-model="form.mobileOrEmail" placeholder="请输入手机号码">
              <i slot="prefix" class="iconfont"> &#xe641; </i>
            </el-input>
          </el-form-item>
          <el-form-item prop="pwd">
            <el-input
              v-model="form.loginPwd"
              type="password"
              placeholder="请输入登录密码"
            >
              <i slot="prefix" class="iconfont"> &#xe65d; </i>
            </el-input>
          </el-form-item>
          <!-- <el-form-item prop="verifyCode">
                  <el-input v-model="form.verifyCode" placeholder="请输入短信验证码">
                    <i  slot="prefix" class="iconfont"> &#xe647; </i>
                    <em slot="suffix" class="yzm"> 发送验证码 </em>
                  </el-input>
                </el-form-item> -->
          <!-- <el-form-item prop="allow">
                     <el-checkbox v-model="form.allow">记住密码</el-checkbox>
                 </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="onSubmit">登录</el-button>
          </el-form-item>
        </el-form>

        <div class="goLogin">
          <router-link :to="{ path: '/Rigist', query: {} }">
            没有账号,去注册
          </router-link>
          <router-link :to="{ path: '/ForgetPwd', query: {} }">
            忘记密码
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { slider, slideritem } from "vue-concise-slider";

import { login } from "@/api/UserService";
import { queryObligeeList } from '@/api/ObligeeService'
import { sendSmsVerifyCode } from "@/api/SmsService";
import { Message } from 'element-ui';
export default {
  name: "Login",
  components: {
    slider,
    slideritem,
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      redirect:this.$route.query.redirect,
      form: {
        mobileOrEmail: "",
        verifyCode: "",
        loginPwd: "",
      },
      rules: {
        name: [
          // { required: true, message: '请输入昵称', trigger: 'blur' }
        ],
      },
    };
  },

  methods: {
    init() {},
    onSubmit() {
      login(this.form).then(({ data, res }) => {
        console.log(data, res);
        this.$store.commit("login", {
          userInfo: data,
          token: res.headers.get("Authorization"),
        });
        this.bus.$emit("refreshInfo");
        this.bus.$emit("refreshVip");
        this.bus.$emit("refreshObligee");
        // if(that.redirect){
        //   that.$router.push(that.redirect);
        // }else{
        //   that.$router.push("/");
        // }

        //查询是否实名认证
        let that = this;
        setTimeout(function(){
          queryObligeeList(1, 100).then(({data}) => {
            // console.log('--------',data.list.length);
            if (data && data.list.length > 0) {
              that.$store.commit('setAuthInfo', data.list[0]);
              if(that.redirect){
                that.$router.push(that.redirect);
              }else{
                that.$router.push("/");
              }
            }else{
              Message({
                type: 'warning',
                message: '请先完成实名认证！'
              });
              that.$router.push({
                path: '/Mine/RealName',
                query: { redirect: that.redirect }
              })
            }
          })
        },300)
      });
      
      
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/loginRigist.scss";
</style>